<template>
  <div class="layout-container">
    <div class="header">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="" />
      </div>
      <ul class="menu-box">
        <li :class="['menu-item', idx == menuIdx ? 'is-active' : '']" v-for="(item, idx) in menuList" :key="idx" @click="handleMenu(idx)" @mouseenter="handleMenuMouseEnter(idx)" @mouseleave="handleMouseLeave">
          <span class="label">{{ item.label }}</span>
          <ul class="menu-chilren-box" v-if="item.children && item.children.length && hoverMenuIdx == idx">
            <li :class="[subMenuIdx == subIdx ? 'is-active' : '']" v-for="(subItem, subIdx) in item.children" :key="subIdx" @click.stop="handleSubmenu(subIdx)">
              {{ subItem.label }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuIdx: 0,
      hoverMenuIdx: -1,
      subMenuIdx: 0,
      menuList: [
        { label: "首页", path: "/home" },
        {
          label: "关于我们",
          path: "/about",
          children: [
            { label: "研究院简介", path: "/about/0" },
            { label: "理事会及监事", path: "/about/1" },
            { label: "研究院章程", path: "/about/2" },
            { label: "管理制度", path: "/about/3" },
            { label: "资质证书", path: "/about/4" },
            { label: "组织架构", path: "/about/5" },
            { label: "合作单位", path: "/about/6" },
          ],
        },
        // { label: "学术交流与合作" },
        // { label: "临床研究与转化" },
        // { label: "健康科普" },
        { label: "专家风采", path: "/expert" },
        {
          label: "信息公开",
          path: "/information",
          children: [
            { label: "年度工作报告", path: "/information/0" },
            { label: "审计报告", path: "/information/1" },
            { label: "捐赠披露", path: "/information/2" },
          ],
        },
        { 
          label: "新闻动态",
          path: "/news/0",
        },
        {
          label: "学术活动",
          path: "/academic",
          children: [
            { label: "学术论坛", path: "/academic/0" },
            { label: "培训沙龙", path: "/academic/1" },
            { label: "病例讨论", path: "/academic/2" },
            { label: "参访班", path: "/academic/3" },
          ],
        },
        { label: "临床科研", path: "/clinical" },
        { label: "对外交流", path: "/exchange" },
        { label: "健康科普", path: "/health" },
        { label: "继续教育", path: "/education" },
        { label: "论文与著作", path: "/other/0" },
      ],
    };
  },
  methods: {
    handleMenu(idx) {
      let { path, children } = this.menuList[idx];
      let currPath = this.$route.path;

      console.log("path", path);

      this.menuIdx = idx;
      this.subMenuIdx = 0;

      if (children && children.length) {
        path = this.menuList[idx].children[0].path;
        this.hoverMenuIdx = idx;
      }

      if (currPath != path) {
        this.$router.push(path);
      }
    },
    handleMenuMouseEnter(idx) {
      // console.log("enter");
      this.hoverMenuIdx = idx;
    },
    handleSubmenu(idx) {
      const menuIdx = this.menuIdx != this.hoverMenuIdx ? this.hoverMenuIdx : this.menuIdx;
      const { path } = this.menuList[menuIdx].children[idx];
      const currPath = this.$route.path;

      this.subMenuIdx = idx;
      this.menuIdx = menuIdx;

      if (currPath != path) {
        this.$router.push(path);
      }
    },
    handleMouseLeave() {
      // console.log("out");
      this.hoverMenuIdx = -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f0f0;
}
.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
}
.header {
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 10px;
  height: 80px;
  .logo {
    width: 154px;
    height: 50px;
    margin-right: 50px;
    img {
      width: 100%;
    }
  }
  .menu-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 100px;
    // justify-content: center;
    flex: 1;
    .menu-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 500;
      padding: 10px 20px;
      color: #3f3a3a;
      cursor: pointer;
      &::after {
        content: "";
        display: block;
        width: 80%;
        height: 5px;
        padding-bottom: 10px;
        border-bottom: 5px solid transparent;
      }

      .label {
        padding: 20px 0 0 0;
      }

      &.is-active,
      &:hover {
        color: #931f25;
        &::after {
          content: "";
          display: block;
          width: 80%;
          height: 5px;
          padding-bottom: 10px;
          border-bottom: 5px solid #931f25;
        }
      }
      .menu-chilren-box {
        position: absolute;
        z-index: 99;
        font-size: 18px;
        top: 95px;
        width: 144px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
        padding: 12px 12px 0 12px;
        li {
          padding-bottom: 12px;
          color: #3f3a3a;
          cursor: pointer;
          &:hover {
            color: #931f25;
          }
          &.is-active {
            color: #931f25;
          }
        }
      }
    }
  }
}
</style>
