import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/Layout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/image/:name+",
    name: "Image",
    component: () => import(/* webpackChunkName: "Image" */ "../views/Image.vue"),
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
      },
      {
        path: "/about/:module+",
        name: "About",
        component: () => import(/* webpackChunkName: "About" */ "../views/About.vue"),
      },
      {
        path: "/information/:module+",
        name: "Information",
        component: () => import(/* webpackChunkName: "Information" */ "../views/Information.vue"),
      },
      {
        path: "/expert",
        name: "Expert",
        component: () => import(/* webpackChunkName: "Expert" */ "../views/Expert.vue"),
      },
      {
        path: "/news/:module+",
        name: "News",
        component: () => import(/* webpackChunkName: "News" */ "../views/News.vue"),
      },
      {
        path: "/newsDetail/:module+",
        name: "NewsDetail",
        component: () => import(/* webpackChunkName: "NewsDetail" */ "../views/NewsDetail.vue"),
      },
      {
        path: "/academic/:module+",
        name: "Academic",
        component: () => import(/* webpackChunkName: "Academic" */ "../views/Academic.vue"),
      },
      {
        path: "/clinical",
        name: "Clinical",
        component: () => import(/* webpackChunkName: "Clinical" */ "../views/Clinical.vue"),
      },
      {
        path: "/exchange",
        name: "Exchange",
        component: () => import(/* webpackChunkName: "Exchange" */ "../views/Exchange.vue"),
      },
      {
        path: "/health",
        name: "Health",
        component: () => import(/* webpackChunkName: "Health" */ "../views/Health.vue"),
      },
      {
        path: "/education",
        name: "Education",
        component: () => import(/* webpackChunkName: "Education" */ "../views/Education.vue"),
      },
      {
        path: "/other/:module+",
        name: "Other",
        component: () => import(/* webpackChunkName: "Other" */ "../views/Other.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
